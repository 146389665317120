@value tablet from "../../ui-kit/breakpoints.module.css";

.tuvBadges {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: tablet) {
  .tuvBadges img {
    height: 60px;
  }
}
