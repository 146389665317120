@value grey200 from "../../../ui-kit/colors.module.css";

.accordion {
}

.accordionItem {
  border-top: 1px solid grey200;
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
}

.accordionItem:first-of-type {
  border-top: none;
}

.accordionTitle {
  all: initial;
  cursor: pointer;
  display: flex;
  padding: 15px 0;
  align-items: center;
  justify-content: space-between;
}

.accordionPanel {
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.contentWrapperString {
  margin-top: -15px;
}

.hasIcon {
  margin: 0 40px 0 56px;
}

.iconChevron {
  margin-inline-start: 16px;
}

.icon {
  margin-right: 16px;
}

.titleText {
  flex-grow: 1;
}

.textContainer {
  padding: 8px 0;
}

.isHidden {
  display: none;
}
