@value mobile, tablet from "../../ui-kit/breakpoints.module.css";

.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 16px;
  max-width: 1080px;
}

@media screen and (max-width: tablet) {
  .container {
    max-width: mobile;
    margin: auto;
  }
}

@media screen and (max-width: mobile) {
  .divider {
    height: 35px;
  }
}
