@value blue100, blue300 from '../../../ui-kit/colors.module.css';

.container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.option {
  all: initial;
  cursor: pointer;
}

.switch {
  width: 36px;
  height: 18px;
  border-radius: 24px;
  background-color: blue100;
  display: flex;
  cursor: pointer;
}

.switchRight {
  justify-content: flex-end;
}

.indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: blue300;
  margin: 1px;
}
