.container {
  position: relative;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  background: transparent;
}

.bar {
  background: #14267b;
  width: 20px;
  height: 2px;
  position: absolute;
  transition: transform 0.3s;
  border-radius: 2px;
  left: 12px;
}

.barTop {
  top: 17px;
  transform-origin: 20% 10%;
}

.barBottom {
  top: 25px;
  transform-origin: 20% 90%;
}
