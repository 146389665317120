.flexRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flexCol {
  display: flex;
  flex-direction: column;
  width: 100%;
}
