@value errorBg from "../../ui-kit/colors.module.css";
@value mobile, tablet, desktop from "../../ui-kit/breakpoints.module.css";

.layout {
  margin: 0 auto;
  padding-top: 100px;
}

.fullpageLayout {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background: errorBg;
  display: flex;
}

.fullpageInnerLayout {
  max-width: 1000px;
  margin: auto;
  display: flex;
  padding: 40px;
}

.innerLayout {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.image {
  max-width: 100%;
  height: auto;
}
.mobileImage {
  margin-top: 40px;
}

.desktopImage {
  display: none;
}

.button {
  margin-top: 40px;
}

.description {
  margin-top: 20px;
  text-align: center;
}

.title {
  text-align: center;
}

@media (min-width: desktop) {
  .layout {
    align-items: center;
    flex-direction: row;
  }

  .innerLayout {
    align-items: flex-start;
    justify-content: center;
  }

  .desktopImage {
    display: block;
    margin-left: 30px;
    min-width: 375px;
  }

  .mobileImage {
    display: none;
  }

  .description {
    text-align: left;
  }

  .title {
    text-align: left;
  }
}
