@value grey100, grey500, navy500 from "../ui-kit/colors.module.css";
@value desktop, tablet from "../ui-kit/breakpoints.module.css";

.grid {
  display: grid;
  grid-template-columns: 1fr;

  gap: 8px;
  margin-bottom: 48px;
}

.topRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 16px;
}

.wrapper {
  padding: 80px 0;
  background: grey100;
}

.container {
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}

.column {
  margin-bottom: 16px;
}

.footerInfo {
  margin-bottom: 40px;
}

.topRow {
  margin-bottom: 48px;
}

.logo {
  margin-bottom: 40px;
}

.column ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.column a {
  color: grey500;
}

.header {
  color: navy500;
  margin-bottom: 16px;
}

.address {
  font-style: normal;
  color: grey500;
  margin: 8px 0;
}

.link {
  padding: 8px 0;
  display: inline-block;
  cursor: pointer;
}

.linkWithIcon {
  display: flex;
  align-items: center;
}

.linkIcon {
  margin-inline-end: 8px;
}

@media (min-width: tablet) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .topRow {
    flex-direction: row;
    align-items: center;
  }

  .bottomRow {
    flex-direction: row;
    align-items: flex-end;
    gap: 0;
  }

  .footerInfo {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .logo {
    margin-bottom: 0;
    width: 200px;
    height: auto;
  }

  .languageSelector {
    width: 50%;
  }
}

@media (min-width: desktop) {
  .grid {
    grid-template-columns: 2fr repeat(3, 1fr);
  }

  .withoutInfo {
    grid-template-columns: repeat(4, 1fr);
  }

  .footerInfo {
    grid-column-end: 1;
    padding-inline-end: 25%;
  }

  .logo {
    margin-bottom: 0;
  }

  .languageSelector {
    width: 400px;
  }
}
