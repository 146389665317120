@value desktop from '../../ui-kit/breakpoints.module.css';
@value grey200, grey300 from '../../ui-kit/colors.module.css';

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.toggleButton {
  width: 100%;
  border: 1px solid grey300;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.valueContainer {
  display: flex;
  align-items: center;
  padding: 12px;
}

.icon {
  padding: 8px;
  border-left: 1px solid grey300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listContainer {
  list-style: none;
  padding: 0;
  width: 100%;
  position: absolute;
  bottom: 55px;
  z-index: 1;
  margin: 0;
}

.list {
  background-color: white;
  border-radius: 8px;
  border: 1px solid grey300;
  padding: 12px;
  margin: 0;
  overflow: auto;
}

.listItem {
  padding: 12px 8px;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  cursor: pointer;
}
