@value navy300,grey200 from '../../../ui-kit/colors.module.css';
@value tablet from "../../../ui-kit/breakpoints.module.css";

.container {
  border: 1px solid grey200;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.image {
  width: 50px;
  height: 50px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.badge {
  margin-inline-start: auto;
  white-space: nowrap;
}

.headlineWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.subTitle {
  margin-bottom: 8px;
}

.description ul {
  margin: 0 0 0 16px;
  padding: 0;
}

.description li {
  margin: 0;
  padding: 0 0 8px 8px;
  font-size: 17px;
  line-height: 150%;
}
.description li::marker {
  color: navy300;
}

@media screen and (min-width: tablet) {
  .contentWrapper {
    flex-direction: row;
  }

  .image {
    width: 100px;
    height: 100px;
  }
}
