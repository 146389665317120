@font-face {
    font-family: 'fontello';
    src: url('../../public/fonts/fontello.eot?69278445');
    src: url('../../public/fonts/fontello.eot?69278445#iefix') format('embedded-opentype'),
    url('../../public/fonts/fontello.woff2?69278445') format('woff2'),
    url('../../public/fonts/fontello.woff?69278445') format('woff'),
    url('../../public/fonts/fontello.ttf?69278445') format('truetype'),
    url('../../public/fonts/fontello.svg?69278445#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone-outlined:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-add_circle:before { content: '\e802'; } /* '' */
.icon-add-plus:before { content: '\e803'; } /* '' */
.icon-add:before { content: '\e804'; } /* '' */
.icon-apple:before { content: '\e805'; } /* '' */
.icon-arrow-left:before { content: '\e806'; } /* '' */
.icon-arrow-right:before { content: '\e807'; } /* '' */
.icon-arrows-ccw:before { content: '\e808'; } /* '' */
.icon-article:before { content: '\e809'; } /* '' */
.icon-calendar:before { content: '\e80a'; } /* '' */
.icon-camera_fill:before { content: '\e80b'; } /* '' */
.icon-camera:before { content: '\e80c'; } /* '' */
.icon-cat-insurance:before { content: '\e80d'; } /* '' */
.icon-cat:before { content: '\e80e'; } /* '' */
.icon-chat-lines:before { content: '\e80f'; } /* '' */
.icon-chat:before { content: '\e810'; } /* '' */
.icon-check-badge:before { content: '\e811'; } /* '' */
.icon-checkmark_circle:before { content: '\e812'; } /* '' */
.icon-checkmark_small:before { content: '\e813'; } /* '' */
.icon-checkmark:before { content: '\e814'; } /* '' */
.icon-chevron-down:before { content: '\e815'; } /* '' */
.icon-chevron-left:before { content: '\e816'; } /* '' */
.icon-chevron-right:before { content: '\e817'; } /* '' */
.icon-chevron-up:before { content: '\e818'; } /* '' */
.icon-clock_filled:before { content: '\e819'; } /* '' */
.icon-clock:before { content: '\e81a'; } /* '' */
.icon-close:before { content: '\e81b'; } /* '' */
.icon-course-complete:before { content: '\e81c'; } /* '' */
.icon-credit-card:before { content: '\e81d'; } /* '' */
.icon-creditcard:before { content: '\e81e'; } /* '' */
.icon-delete_btn:before { content: '\e81f'; } /* '' */
.icon-delete_recycle:before { content: '\e820'; } /* '' */
.icon-delete:before { content: '\e821'; } /* '' */
.icon-dental-damage:before { content: '\e822'; } /* '' */
.icon-dental-health:before { content: '\e823'; } /* '' */
.icon-description:before { content: '\e824'; } /* '' */
.icon-document-check:before { content: '\e825'; } /* '' */
.icon-document:before { content: '\e826'; } /* '' */
.icon-dog_check:before { content: '\e827'; } /* '' */
.icon-dog_health:before { content: '\e828'; } /* '' */
.icon-dog_training:before { content: '\e829'; } /* '' */
.icon-dog-food:before { content: '\e82a'; } /* '' */
.icon-dog-health:before { content: '\e82b'; } /* '' */
.icon-dog-house:before { content: '\e82c'; } /* '' */
.icon-dog-insurance:before { content: '\e82d'; } /* '' */
.icon-dog-jump:before { content: '\e82e'; } /* '' */
.icon-dog-lost:before { content: '\e82f'; } /* '' */
.icon-dog-mouth-protection:before { content: '\e830'; } /* '' */
.icon-dog-phone:before { content: '\e831'; } /* '' */
.icon-dog-profile:before { content: '\e832'; } /* '' */
.icon-dog-search:before { content: '\e833'; } /* '' */
.icon-dog:before { content: '\e834'; } /* '' */
.icon-edit-pen:before { content: '\e835'; } /* '' */
.icon-edit:before { content: '\e836'; } /* '' */
.icon-facebook:before { content: '\e837'; } /* '' */
.icon-favorite-filled:before { content: '\e838'; } /* '' */
.icon-favorite-outline:before { content: '\e839'; } /* '' */
.icon-first-prize:before { content: '\e83a'; } /* '' */
.icon-google-black:before { content: '\e83b'; } /* '' */
.icon-google:before { content: '\e83c'; } /* '' */
.icon-guidance:before { content: '\e83d'; } /* '' */
.icon-health-hands:before { content: '\e83e'; } /* '' */
.icon-health:before { content: '\e83f'; } /* '' */
.icon-heart:before { content: '\e840'; } /* '' */
.icon-hourglass:before { content: '\e841'; } /* '' */
.icon-house:before { content: '\e842'; } /* '' */
.icon-identity:before { content: '\e843'; } /* '' */
.icon-information-circle:before { content: '\e844'; } /* '' */
.icon-instagram:before { content: '\e845'; } /* '' */
.icon-insurance-lassie:before { content: '\e846'; } /* '' */
.icon-isurance-1:before { content: '\e847'; } /* '' */
.icon-language:before { content: '\e848'; } /* '' */
.icon-learn-new:before { content: '\e849'; } /* '' */
.icon-learn:before { content: '\e84a'; } /* '' */
.icon-link:before { content: '\e84b'; } /* '' */
.icon-linkedin:before { content: '\e84c'; } /* '' */
.icon-lock:before { content: '\e84d'; } /* '' */
.icon-love-angel:before { content: '\e84e'; } /* '' */
.icon-mail:before { content: '\e84f'; } /* '' */
.icon-medical-scalpel:before { content: '\e850'; } /* '' */
.icon-medication:before { content: '\e851'; } /* '' */
.icon-message-bubble:before { content: '\e852'; } /* '' */
.icon-mix-breed:before { content: '\e853'; } /* '' */
.icon-new:before { content: '\e854'; } /* '' */
.icon-notification:before { content: '\e855'; } /* '' */
.icon-pad-lock:before { content: '\e856'; } /* '' */
.icon-paw:before { content: '\e857'; } /* '' */
.icon-pdf:before { content: '\e858'; } /* '' */
.icon-play:before { content: '\e859'; } /* '' */
.icon-points:before { content: '\e85a'; } /* '' */
.icon-quiz_wrong:before { content: '\e85b'; } /* '' */
.icon-quiz:before { content: '\e85c'; } /* '' */
.icon-radio-selected:before { content: '\e85d'; } /* '' */
.icon-radio:before { content: '\e85e'; } /* '' */
.icon-read-glasses:before { content: '\e85f'; } /* '' */
.icon-redo:before { content: '\e860'; } /* '' */
.icon-referral-new:before { content: '\e861'; } /* '' */
.icon-referral:before { content: '\e862'; } /* '' */
.icon-refferal:before { content: '\e863'; } /* '' */
.icon-reward:before { content: '\e864'; } /* '' */
.icon-scan-radiology:before { content: '\e865'; } /* '' */
.icon-school:before { content: '\e866'; } /* '' */
.icon-search-breed:before { content: '\e867'; } /* '' */
.icon-search:before { content: '\e868'; } /* '' */
.icon-share:before { content: '\e869'; } /* '' */
.icon-shield-heart:before { content: '\e86a'; } /* '' */
.icon-shield-home:before { content: '\e86b'; } /* '' */
.icon-shield-search:before { content: '\e86c'; } /* '' */
.icon-shield-travel:before { content: '\e86d'; } /* '' */
.icon-star:before { content: '\e86e'; } /* '' */
.icon-syringe:before { content: '\e86f'; } /* '' */
.icon-tag:before { content: '\e870'; } /* '' */
.icon-thermometer:before { content: '\e871'; } /* '' */
.icon-trophy:before { content: '\e872'; } /* '' */
.icon-undo:before { content: '\e873'; } /* '' */
.icon-upload-photo:before { content: '\e874'; } /* '' */
.icon-vet-meeting:before { content: '\e875'; } /* '' */
.icon-veterinary_call:before { content: '\e876'; } /* '' */
.icon-video-play:before { content: '\e877'; } /* '' */
.icon-warning-fill:before { content: '\e878'; } /* '' */
.icon-youtube:before { content: '\e879'; } /* '' */


