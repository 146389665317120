@value grey400 from "../colors.module.css";
@value mobile, tablet from "../breakpoints.module.css";

.display {
  font-size: 60px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.h1 {
  font-size: 48px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.h2 {
  font-size: 36px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.h3 {
  font-size: 24px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.h4 {
  font-size: 20px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.h5 {
  font-size: 16px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.h6 {
  font-size: 14px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.body1 {
  font-size: 20px;
  line-height: 150%;
  font-family: var(--font-eina);
}

.body2 {
  font-size: 17px;
  line-height: 150%;
  font-family: var(--font-eina);
}

.body3 {
  font-size: 14px;
  line-height: 150%;
  font-family: var(--font-eina);
}

.body4 {
  font-size: 12px;
  line-height: 135%;
  font-family: var(--font-eina);
}

.truncate {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: tablet) {
  .display {
    font-size: 36px;
  }

  .h1 {
    font-size: 36px;
  }

  .h2 {
    font-size: 24px;
  }

  .h3 {
    font-size: 20px;
  }

  .h4 {
    font-size: 16px;
  }

  .h5 {
    font-size: 14px;
  }

  .h6 {
    font-size: 14px;
  }
}
