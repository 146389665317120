@value blue100 from '../../colors.module.css';

.frame {
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.round {
  border-radius: 50%;
  background-color: blue100;
}
