@value white, blue300, grey100, grey400 from "../../../ui-kit/colors.module.css";
@value desktop from "../../../ui-kit/breakpoints.module.css";

.wrapper {
  gap: 8px;
  display: none;
}

@media (min-width: desktop) {
  .wrapper {
    display: flex;
    align-items: center;
  }
}

.topLevelItem {
  all: initial;
  padding: 12px 8px;
  margin: 0;
  display: flex;
  border-radius: 8px;
  align-self: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.menuItems {
  outline: 0;
}

.isExpanded {
  color: blue300;
}

.topLevelItem:hover,
.topLevelItem[data-selected] {
  background-color: grey100;
}

.iconWrapper {
  width: 16px;
  height: 16px;
  margin-inline-start: 8px;
}

.menuList {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  margin: 0;
  z-index: 2147483021;
}

.menuList[hidden] {
  display: none;
}

.menuList > .topLevelItem {
  width: 100%;
}

.category {
  color: grey400;
  text-transform: uppercase;
  font-size: 12px;
}
