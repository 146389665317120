@value navy300,grey200, grey300 from '../../../ui-kit/colors.module.css';
@value tablet from "../../../ui-kit/breakpoints.module.css";

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

.input {
  flex: 1;
  padding: 8px 8px;
  border: 0;
}

.button {
  all: initial;
  cursor: pointer;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listWrapper {
  position: relative;
}

.toggleButton {
  border-left: 1px solid grey300;
}

.listContainer {
  list-style: none;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 12px;
  z-index: 1;
  margin: 0;
}

.list {
  background-color: white;
  border-radius: 8px;
  border: 1px solid grey200;
  padding: 8px;
  margin: 0;
  max-height: 300px;
  overflow: auto;
}

.listItem {
  padding: 12px 8px;
  border-radius: 4px;
  cursor: pointer;
}
