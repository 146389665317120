@value grey200 from "../../../ui-kit/colors.module.css";

.wrapper {
  position: relative;
}

.container {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 24px 0;
  scroll-behavior: smooth;
}

.container::before,
.container::after {
  content: "";
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
}

.showPrev::before {
  left: 0;
  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.showNext::after {
  right: 0;
  background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.track {
  display: grid;
  gap: 16px;
  margin: auto;
  grid-auto-flow: column;
  width: fit-content;
  margin-bottom: 24px;
}

.track > div {
  flex-shrink: 0;
}

.button {
  all: initial;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  border: 1px solid grey200;
  width: 44px;
  height: 44px;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 30px 8px 0px rgba(204, 204, 204, 0),
    0px 19px 8px 0px rgba(204, 204, 204, 0.01),
    0px 11px 7px 0px rgba(204, 204, 204, 0.05),
    0px 5px 5px 0px rgba(204, 204, 204, 0.09),
    0px 1px 3px 0px rgba(204, 204, 204, 0.1);
  z-index: 1;
}

.buttonPrev {
  left: 5px;
}

.buttonNext {
  right: 5px;
}

.buttonShow {
  display: flex;
}
