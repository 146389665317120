@value blue300,gold500, error, grey200 from "../colors.module.css";

.button {
  position: relative;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  overflow: hidden;
  outline: none;
}

.variantDefault {
  background-color: blue300;
}

.variantDefault:hover {
  background-color: #4f5d9c;
}

.variantDanger {
  background-color: error;
}

button.variantOutlined {
  background-color: transparent;
  border-width: 1px;
  border-color: blue300;
  border-style: solid;
  color: black;
}

button.variantOutlined:hover {
  border-color: #4f5d9c;
}

.variantOutlined.disabled {
  background-color: transparent;
  border-width: 1px;
  border-color: grey200;
  border-style: solid;
  color: grey200;
}

.sizeBig {
  cursor: pointer;
  width: 100%;
  border: none;
  min-height: 48px;
}

.small {
  min-height: 42px;
}

.big {
  min-height: 56px;
}

.radiusBig {
  border-radius: 100px;
}

.disabled {
  background-color: grey200;
  pointer-events: none;
}

.loadingContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
}

.iconContainer {
  margin-top: 3px;
}
.golderButtonContainer {
  background-color: gold500;
  padding: 2px;
  border-radius: 100px;
}

.radiusSquareRounded {
  border-radius: 8px;
}
