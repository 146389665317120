@value blue300, grey200 from "../../ui-kit/colors.module.css";
@value tablet, desktop from "../../ui-kit/breakpoints.module.css";

.container {
  height: var(--header-height);
  width: 100%;
  padding: 0 20px 0 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms;
  z-index: 214748300;
  position: sticky;
  top: 0;
  border-bottom: 1px solid grey200;
}

.mobileContainer {
  display: none;
}

.desktopContainer {
  display: flex;
}

@media screen and (max-width: desktop) {
  .mobileContainer {
    display: flex;
  }

  .desktopContainer {
    display: none;
  }
}

.hideHeader {
  top: calc(var(--header-height) * -1);
}

.content {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
}

.logo {
  width: 105px;
}

.flex {
  display: flex;
}

@media (max-width: tablet) {
  .container {
    height: var(--header-height-tablet);
    padding: 0 20px 0 16px;
  }
  .logo {
    width: 88px;
  }

  .hideHeader {
    top: calc(var(--header-height-tablet) * -1);
  }
}

.linkButton {
  border-radius: 999px;
  background-color: blue300;
  margin-inline-start: 12px;
  color: white;
  padding: 12px 18px;
  font-family: var(--font-eina-semi-bold);
  font-size: 17px;
  line-height: 150%;
  text-align: center;
}
