@value tablet from "../../../ui-kit/breakpoints.module.css";
@value grey200, grey400 from "../../../ui-kit/colors.module.css";

.mobilePanel {
  z-index: 2147483009; /* one unit higher than intercom */
  position: fixed;
  right: 0;
  width: 100%;
  top: var(--header-height-tablet);
  height: calc(100vh - var(--header-height-tablet));
  background: white;
  display: none;
  transition: opacity 200ms 0ms ease-in-out;
  padding: 0 20px;
  flex-direction: column;
  opacity: 0;
  overflow: auto;
}

.bottomSectionLinks {
  margin-top: auto;
  padding-bottom: 20px;
}

.signupButton {
  width: calc(100% - 70px);
  margin-top: 24px;
  min-height: 47px;
}

@media screen and (min-width: tablet) {
  .mobilePanel {
    top: var(--header-height);
    width: 60%;
    max-height: calc(100vh - var(--header-height));
  }
}

.wrapper {
  height: calc(100vh - 54px);
  display: flex;
  flex-direction: column;
}

.wrapper > * {
  border-top: 1px solid grey200;
}

.topLevelLink {
  padding: 22px 0;
  display: block;
}

.wrapper > *:first-child {
  border: none;
}

.wrapper > *:last-child {
  border: none;
}

.active {
  display: flex;
  opacity: 1;
}

.backdrop {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  opacity: 0;
}

.backdrop.active {
  display: block;
  z-index: 5;
  opacity: 1;
  transition: opacity 200ms 0ms ease-in-out;
}

.menuLink {
  padding: 18px 16px;
  display: block;
}

.categoryHeader {
  text-transform: uppercase;
  color: grey400;
  margin-top: 25px;
  display: block;
}
