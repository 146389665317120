html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 80px;
  --header-height-tablet: 54px;
  --signup-header-height: 76px;
}

.app {
  font-family: sans-serif;
  font-family: var(--font-eina);
  font-weight: 400;
  font-size: 17px;
}

input {
  font-family: sans-serif;
  font-family: var(--font-eina);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: sans-serif;
  font-family: var(--font-eina);
}

a {
  text-decoration: none;
  color: black;
}

p {
  font-family: sans-serif;
  font-family: var(--font-eina);
  line-height: 150%;
  margin-block-start: 12px;
  margin-block-end: 12px;
}

strong,
em {
  font-family: var(--font-eina-semi-bold);
  font-weight: 400;
}

input {
  outline: none;
}

/* Get rid of annoying autozoom when focus on inputs in ios safari */
select,
textarea,
input,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 17px;
}

/* Hide tracking pixel since it affects the layout */
img[src*="adnxs.com"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

video {
  mask-image: -webkit-radial-gradient(white, black);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
